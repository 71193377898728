import { PlusIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import H1 from '../../../../components/H1';
import ModalEquipment from '../../../../components/Modals/ModalEquipment';
import Table from '../../../../components/Table/Table';
import AssetsService from '../../../../services/AssetsService';
import { Equipment } from '../../../../services/models/assets';

const DashboardEquipments = () => {
  const [equipments, setEquipments] = useState<Equipment[]>();
  const [selectedEquipment, setSelectedEquipment] = useState<Partial<Equipment>>();

  const tableHeader = useMemo(() => ([
    { name: 'Image', key: 'image', hidden: true },
    { name: 'Modèle', key: 'model' },
    { name: '€ / j', key: 'priceDay' },
    { name: '€ / 1/2j', key: 'priceHalfDay' },
    { name: '€ / 2h', key: 'price2Hours' },
    { name: '€ / h', key: 'price1Hour' },
    { name: 'Caution', key: 'deposit' },
    { name: 'Status', hidden: true, right: true, key: 'status' },
  ]), []);

  const tableData = useMemo(() => (
    equipments?.map((equipment) => ({
      image: (
        <img src={equipment.imageUrl} alt={equipment.model} className="w-10 h-10 rounded-lg" />
      ),
      model: (
        <div className="max-w-80">
          <p className="text-gray-900 text-wrap font-medium line-clamp-2">
            {equipment.model}
          </p>
          <p className="text-gray-700 text-wrap text-xs line-clamp-2">
            {equipment.description}
          </p>
        </div>
      ),
      priceDay: `${(equipment.priceDay / 100).toFixed(2)} €`,
      priceHalfDay: `${(equipment.priceHalfDay / 100).toFixed(2)} €`,
      price2Hours: `${(equipment.price2Hours / 100).toFixed(2)} €`,
      price1Hour: `${(equipment.price1Hour / 100).toFixed(2)} €`,
      deposit: `${(equipment.deposit / 100).toFixed(2)} €`,
      id: equipment.id,
      key: equipment.id,
    })) ?? []
  ), [equipments]);

  useEffect(() => {
    AssetsService.getEquipments()
      .then((response) => {
        setEquipments(response.equipments);
      })
      .catch(console.warn);
  }, []);

  const onEquipmentPress = (data: (typeof tableData)[number]) => {
    setSelectedEquipment(equipments?.find((equipment) => equipment.id === data.id));
  };

  const closeAndRefresh = () => {
    setSelectedEquipment(undefined);
    AssetsService.getEquipments()
      .then((response) => {
        setEquipments(response.equipments);
      })
      .catch(console.warn);
  };

  return (
    <div className="pb-5 sm:pb-8 lg:pb-12">
      <H1>Équipements</H1>
      <div className="flex justify-end mb-5">
        <button
          type="button"
          className="px-4 py-2 bg-orange-400 rounded-full text-white font-semibold shadow disabled:shadow-none disabled:bg-gray-300"
          onClick={() => setSelectedEquipment({})}
        >
          <PlusIcon width={20} height={20} className="inline-block" />
          <span className="ml-2">Ajouter un équipement</span>
          
        </button>
      </div>
      <Table
        header={tableHeader}
        data={tableData}
        onRowPress={onEquipmentPress}
      />
      <ModalEquipment
        equipment={selectedEquipment}
        onClose={closeAndRefresh}
        visible={!!selectedEquipment}
      />
    </div>
  );
};

export default DashboardEquipments;
