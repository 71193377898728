import { v4 as uuidv4 } from 'uuid';
import { Locale } from '../types/common';
import { RegisterBody, User } from './models/user';
import Service from './Service';

class UserService {
  private static URL_BASE = process.env.REACT_APP_URL_API;

  private static URL_LOGIN = () => `${this.URL_BASE}/user/login`;
  private static URL_REGISTER = () => `${this.URL_BASE}/user/register`;
  private static URL_VALIDATE_TOKEN = () => `${this.URL_BASE}/user/validate-token`;

  static login(email: string, password: string, lang: Locale): Promise<User | { message: string}> {
    const deviceId = localStorage.getItem('device_id') || uuidv4();
    const data = {
      email,
      password,
      lang,
      device_id: deviceId,
    };

    localStorage.setItem('device_id', deviceId);

    return Service.fetch(this.URL_LOGIN(), {
      method: 'POST',
      body: data,
    }, true);
  }

  static validateToken(): Promise<boolean> {
    return Service.fetch(this.URL_VALIDATE_TOKEN());
  }

  static register(data: RegisterBody): Promise<User | { message: string}> {
    return Service.fetch(this.URL_REGISTER(), {
      method: 'POST',
      body: data,
    });
  }
}

export default UserService;
