import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { REGEX_EMAIL, REGEX_PASSWORD } from '../services/models/user';
import Service, { isServiceReady } from '../services/Service';
import UserService from '../services/UserService';
import { Field, Locale } from '../types/common';



const Signup = () => {
  const [email, setEmail] = useState<Field>({ value: '' });
  const [confirmEmail, setConfirmEmail] = useState<Field>({ value: '' });
  const [password, setPassword] = useState<Field>({ value: '' });
  const [confirmPassword, setConfirmPassword] = useState<Field>({ value: '' });
  const [firstName, setFirstName] = useState<Field>({ value: '' });
  const [lastName, setLastName] = useState<Field>({ value: '' });
  const [street, setStreet] = useState<Field>({ value: '' });
  const [street2, setStreet2] = useState<Field>({ value: '' });
  const [postalCode, setPostalCode] = useState<Field>({ value: '' });
  const [city, setCity] = useState<Field>({ value: '' });
  const [country, setCountry] = useState<Field>({ value: '' });
  const [termsAccepted, setTermsAccepted] = useState(false);

  const submitting = useRef(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isServiceReady) {
      Service.init(() => {
        localStorage.removeItem('token');
        navigate('/login');
      });
    }
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      verifyEmail();
      verifyConfirmEmail();
      verifyPassword();
      verifyConfirmPassword();
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    email.value,
    confirmEmail.value,
    password.value,
    confirmPassword.value,
  ]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail({ value: event.target.value });
  };

  const handleConfirmEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmEmail({ value: event.target.value });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ value: event.target.value });
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword({ value: event.target.value });
  };

  const verifyEmail = () => {
    setEmail((prev) => {
      if (prev.value && prev.value.length > 0) {
        return { value: prev.value, error: !REGEX_EMAIL.test(prev.value) };
      }
      return { value: prev.value };
    });
  };

  const verifyConfirmEmail = () => {
    setConfirmEmail((prev) => {
      if (prev.value && prev.value.length > 0) {
        return { value: prev.value, error: prev.value !== email.value };
      }
      return { value: prev.value };
    });
  };

  const verifyPassword = () => {
    setPassword((prev) => {
      if (prev.value && prev.value.length > 0) {
        const passwordRegex = REGEX_PASSWORD;
        return { value: prev.value, error: !passwordRegex.test(prev.value) };
      }
      return { value: prev.value };
    });
  };

  const verifyConfirmPassword = () => {
    setConfirmPassword((prev) => {
      if (prev.value && prev.value.length > 0) {
        return { value: prev.value, error: prev.value !== password.value };
      }
      return { value: prev.value };
    });
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstName({ value: event.target.value });
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName({ value: event.target.value });
  };

  const handleStreetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStreet({ value: event.target.value });
  };

  const handleStreet2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStreet2({ value: event.target.value });
  };

  const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostalCode({ value: event.target.value });
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity({ value: event.target.value });
  };

  const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCountry({ value: event.target.value });
  };

  const handleTermsAcceptedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(event.target.checked);
  };

  const checkData = () => {
    return (
      (email.value?.length ?? 0) > 0 && !email.error
      && (password.value?.length ?? 0) > 0 && !password.error
      && (firstName.value?.length ?? 0) > 0 && !firstName.error
      && (lastName.value?.length ?? 0) > 0 && !lastName.error
      && (street.value?.length ?? 0) > 0 && !street.error
      && (postalCode.value?.length ?? 0) > 0 && !postalCode.error
      && (city.value?.length ?? 0) > 0 && !city.error
      && (country.value?.length ?? 0) > 0 && !country.error
      && termsAccepted
    );
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!submitting.current) {
      submitting.current = true;
      if (checkData()) {
        UserService.register({
          email: email.value!,
          password: password.value!,
          confirmPassword: confirmPassword.value!,
          firstName: firstName.value!,
          lastName: lastName.value!,
          street: street.value!,
          street2: street2.value!,
          postalCode: postalCode.value!,
          city: city.value!,
          country: country.value!,
          termsAccepted,
          lang: Locale.EN,
        })
          .then((user) => {
            if (user && 'token' in user && user.token) {
              localStorage.setItem('token', user.token);
              navigate('/');
            } else if (user && 'message' in user) {
              toast.error(user.message);
            }
          })
          .catch(console.warn)
          .finally(() => {
            submitting.current = false;
          });
      }
    }
  };

  return (
    <div className="flex items-center justify-center sm:my-10">
      <form
        className="bg-white p-16 rounded shadow-md"
        onSubmit={handleSubmit}
      >
        <h1 className="text-2xl font-bold mb-8">Création de compte</h1>
        <div className="mb-4 relative">
          <label className="block mb-2" htmlFor="email">
            Email
          </label>
          <input
            className={`shadow appearance-none border ${email.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
            id="email"
            type="email"
            value={email.value}
            onChange={handleEmailChange}
          />
          {email.error && (
            <p className="absolute text-red-500 text-xs italic right-0">L'email n'est pas valide.</p>
          )}
        </div>
        <div className="mb-4 relative">
          <label className="block mb-2" htmlFor="email">
            Confirmer l'email
          </label>
          <input
            className={`shadow appearance-none border ${confirmEmail.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
            id="email"
            type="email"
            value={confirmEmail.value}
            onChange={handleConfirmEmailChange}
          />
          {confirmEmail.error && (
            <p className="absolute text-red-500 text-xs italic right-0">L'email ne correspond pas.</p>
          )}
        </div>
        <div className='flex gap-5'>
          <div className="mb-4 relative">
            <label className="block mb-2" htmlFor="password">
              Mot de passe
            </label>
            <input
              className={`shadow appearance-none border ${password.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
              id="password"
              type="password"
              value={password.value}
              onChange={handlePasswordChange}
            />
            {password.error && (
              <p className="absolute text-red-500 text-xs italic right-0">Le mot de passe n'est pas valide.</p>
            )}
          </div>
          <div className="mb-4 relative">
            <label className="block mb-2" htmlFor="password">
              Confirmer le mot de passe
            </label>
            <input
              className={`shadow appearance-none border ${password.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
              id="confirm_password"
              type="password"
              value={confirmPassword.value}
              onChange={handleConfirmPasswordChange}
            />
            {password.error && (
              <p className="absolute text-red-500 text-xs italic right-0">Le mot de passe ne correspond pas.</p>
            )}
          </div>
        </div>
        <div className="flex gap-5">
          <div className="mb-4 relative">
            <label className="block mb-2" htmlFor="first_name">
              Prénom
            </label>
            <input
              className={`shadow appearance-none border ${firstName.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
              id="first_name"
              value={firstName.value}
              onChange={handleFirstNameChange}
            />
          </div>
          <div className="mb-4 relative">
            <label className="block mb-2" htmlFor="last_name">
              Nom
            </label>
            <input
              className={`shadow appearance-none border ${lastName.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
              id="last_name"
              value={lastName.value}
              onChange={handleLastNameChange}
            />
          </div>
        </div>
        <div className="mb-4 relative">
          <label className="block mb-2" htmlFor="street">
            Adresse
          </label>
          <input
            className={`shadow appearance-none border ${street.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
            id="street"
            value={street.value}
            onChange={handleStreetChange}
          />
        </div>
        <div className="mb-4 relative">
          <label className="block mb-2" htmlFor="street2">
            Complément d'adresse
          </label>
          <input
            className={`shadow appearance-none border ${street2.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
            id="street2"
            value={street2.value}
            onChange={handleStreet2Change}
          />
        </div>
        <div className="flex gap-5">
          <div className="mb-4 relative">
            <label className="block mb-2" htmlFor="postal_code">
              Code postale
            </label>
            <input
              className={`shadow appearance-none border ${postalCode.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
              id="postal_code"
              value={postalCode.value}
              onChange={handlePostalCodeChange}
            />
          </div>
          <div className="mb-4 relative">
            <label className="block mb-2" htmlFor="city">
              Ville
            </label>
            <input
              className={`shadow appearance-none border ${city.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
              id="city"
              value={city.value}
              onChange={handleCityChange}
            />
          </div>
        </div>
        <div className="mb-4 relative">
          <label className="block mb-2" htmlFor="country">
            Pays
          </label>
          <input
            className={`shadow appearance-none border ${country.error ? 'border-red-500' : 'border-gray-300'} rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
            id="country"
            value={country.value}
            onChange={handleCountryChange}
          />
        </div>
        <div className="mb-4 relative">
          <input
            type="checkbox"
            id="terms"
            checked={termsAccepted}
            onChange={handleTermsAcceptedChange}
          />
          <label className="inline mb-2 ml-2" htmlFor="terms">
            J'accepte les conditions d'utilisation
          </label>
        </div>
        <button
          type="submit"
          className="bg-blue-500 block p-2 rounded text-white w-full disabled:bg-gray-400"
          disabled={!checkData()}
        >
          S'inscrire
        </button>
        <div className="mt-4 text-center">
          <a className="text-blue-500" href="/login">
            Se connecter
          </a>
        </div>
      </form>
    </div>
  );
};

export default Signup;
