import { MoonIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Day, Location } from '../../../../../services/models/assets';

interface ScheduleProps<T extends Location | Partial<Location>> {
  location?: T;
  onLocationChange: (location: T) => void;
}

const DayName: { [day: number]: string } = {
  [Day.MONDAY]: 'Lundi',
  [Day.TUESDAY]: 'Mardi',
  [Day.WEDNESDAY]: 'Mercredi',
  [Day.THURSDAY]: 'Jeudi',
  [Day.FRIDAY]: 'Vendredi',
  [Day.SATURDAY]: 'Samedi',
  [Day.SUNDAY]: 'Dimanche',
};

Object.freeze(DayName);

const Schedule = <T extends Location | Partial<Location>>({ location, onLocationChange }: ScheduleProps<T>) => {
  const [updatedLocation, setUpdatedLocation] = useState<T>();
  const [schedules, setSchedules] = useState(() => {
    const newSchedules = [];

    for (let i = 0; i <= Day.SUNDAY; i++) {
      const schedule = location?.schedules?.filter((schedule) => schedule.day === i as Day) ?? [];

      newSchedules.push({
        day: i as Day,
        enabled: schedule.length > 0,
        schedule,
      });
    }

    return newSchedules;
  });

  useEffect(() => {
    if (updatedLocation) {
      setUpdatedLocation(() => {
        const newUpdatedLocation = {
          ...updatedLocation,
          schedules: schedules.flatMap((schedule) => schedule.enabled ? schedule.schedule : []),
        };
        
        onLocationChange(newUpdatedLocation);
        return newUpdatedLocation;
      });
    } else {
      setUpdatedLocation(location);
    }
  }, [schedules]);

  useEffect(() => {
    setUpdatedLocation(location);
  }, [location]);

  const toggleDay = (day: Day) => {
    setSchedules((prev) => {
      const newSchedules = [...prev];

      newSchedules[day as number] = {
        ...newSchedules[day as number],
        enabled: !newSchedules[day as number].enabled,
        schedule: (!newSchedules[day as number].enabled && newSchedules[day as number].schedule.length === 0)
          ? [{ locationId: location!.id!, day, startTime: '00:00', endTime: '00:00' }]
          : newSchedules[day as number].schedule,
      };

      return newSchedules;
    });
  };

  const addSchedule = (day: Day) => {
    setSchedules((prev) => {
      const newSchedules = [...prev];

      newSchedules[day as number] = {
        ...newSchedules[day as number],
        schedule: [
          ...newSchedules[day as number].schedule,
          {
            locationId: location!.id!,
            day,
            startTime: newSchedules[day as number].schedule[newSchedules[day as number].schedule.length - 1].endTime,
            endTime: newSchedules[day as number].schedule[newSchedules[day as number].schedule.length - 1].endTime,
          }
        ],
      };

      return newSchedules;
    });
  };

  const removeSchedule = (day: Day, index: number) => {
    setSchedules((prev) => {
      const newSchedules = [...prev];

      newSchedules[day as number] = {
        ...newSchedules[day as number],
        schedule: newSchedules[day as number].schedule.filter((_, i) => i !== index),
      };

      return newSchedules;
    });
  };

  const updateSchedule = (day: Day, index: number, key: 'startTime' | 'endTime', value: string) => {
    setSchedules((prev) => {
      const newSchedules = [...prev];

      newSchedules[day as number] = {
        ...newSchedules[day as number],
        schedule: newSchedules[day as number].schedule.map((schedule, i) => {
          if (i === index) {
            return {
              ...schedule,
              startTime: key === 'startTime' ? value : schedule.startTime,
              endTime: key === 'endTime' ? value : (value > schedule.endTime ? value : schedule.endTime),
            };
          }

          return schedule;
        }),
      };

      return newSchedules;
    });
  };

  return (
    <div>
      {schedules.map((schedule) => (
        <div className="py-5 flex justify-between border-b border-gray-300" key={schedule.day}>
          <div className="flex gap-5 items-center self-start h-11 pl-5">
            <button
              type="button"
              className={`rounded-full h-8 w-14 ${schedule.enabled ? 'bg-green-400' : 'bg-gray-500' } relative transition-all`}
              onClick={() => toggleDay(schedule.day)}
            >
              <div className={`h-6 w-6 bg-white shadow absolute left-1 ${schedule.enabled ? 'translate-x-6' : 'translate-x-0'} top-1 rounded-full transition-all`} />
            </button>
            <p className="font-medium">{DayName[schedule.day] as string}</p>
          </div>
          <div className="flex flex-col gap-5">
            {schedule.enabled ? (
              schedule.schedule.map((range, index) => (
                <div className="flex gap-5 items-center pr-5" key={`${schedule.day}-${index}`}>
                  <input
                    className="shadow border border-gray-300 h-11 px-4 rounded"
                    id={`${schedule.day}-start-time-${index}`}
                    type="time"
                    value={range.startTime}
                    onChange={(e) => updateSchedule(schedule.day, index, 'startTime', e.target.value)}
                  />
                  <p>à</p>
                  <input
                    className="shadow border border-gray-300 h-11 px-4 rounded"
                    id={`${schedule.day}-end-time-${index}`}
                    type="time"
                    value={range.endTime}
                    onChange={(e) => updateSchedule(schedule.day, index, 'endTime', e.target.value)}
                  />

                  {index === 0 ? (
                    <button
                      type="button"
                      tabIndex={-1}
                      className="p-1.5 items-center justify-center border border-gray-300 rounded-full shadow hover:bg-gray-50"
                      onClick={() => addSchedule(schedule.day)}
                    >
                    <PlusIcon height={20} />
                    </button>
                  ) : (
                    <button
                      type="button"
                      tabIndex={-1}
                      className="p-1.5 items-center justify-center border border-gray-300 rounded-full shadow hover:bg-gray-50"
                      onClick={() => removeSchedule(schedule.day, index)}
                    >
                      <TrashIcon height={20} />
                    </button>
                  )}
                </div>
              ))
            ) : (
              <div className="h-full flex gap-5 items-center opacity-30">
                <div className="p-1.5">
                  <MoonIcon height={20} />
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Schedule;
