import { MapPinIcon } from '@heroicons/react/24/solid';
import React, { useEffect, useRef } from 'react';
import Mapbox, { MapRef, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

interface MapProps {
  coordinates?: {
    lat: number;
    lng: number;
  };
  markerCoordinates?: {
    lat: number;
    lng: number;
  };
  onCoordinatesChange: (coordinates: { lat: number; lng: number }) => void;
}

const Map: React.FC<MapProps> = React.memo(({ coordinates, markerCoordinates, onCoordinatesChange }) => {
  const mapRef = useRef<MapRef>(null);

  useEffect(() => {
    if (coordinates && mapRef.current) {
      mapRef.current.jumpTo({
        center: [coordinates.lng, coordinates.lat],
        zoom: 15,
      });
    }
  }, [coordinates]);

  return (
    <Mapbox
      ref={mapRef}
      reuseMaps
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      initialViewState={{
        latitude: coordinates?.lat ?? 46.603354,
        longitude: coordinates?.lng ?? 1.888334,
        zoom: 5,
      }}
      style={{ width: '100%', height: '100%', borderRadius: '1rem', overflow: 'hidden' }}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      logoPosition="bottom-right"
      onClick={(e) => onCoordinatesChange(e.lngLat)}
    >
      {markerCoordinates && (
        <Marker
          latitude={markerCoordinates.lat}
          longitude={markerCoordinates.lng}
          anchor="bottom"
          style={{ zIndex: 1000 }}
        >
          <div>
            <MapPinIcon height={48} width={48} color="#FB923C" />
          </div>
        </Marker>
      )}
    </Mapbox>
  );
});

export default Map;
