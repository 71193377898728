import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Service, { isServiceReady } from '../services/Service';
import UserService from '../services/UserService';
import { Field, Locale } from '../types/common';

const Login = () => {
  const [email, setEmail] = useState<Field>({ value: '' });
  const [password, setPassword] = useState<Field>({ value: '' });
  const [showPassword, setShowPassword] = useState(false);

  const submitting = useRef(false);

  const navigate = useNavigate();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail({ value: e.target.value });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword({ value: e.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!submitting.current) {
      submitting.current = true;
      UserService.login(email.value!, password.value!, Locale.EN)
        .then((user) => {
          if (user && 'token' in user && user.token) {
            localStorage.setItem('token', user.token);
            navigate('/');
          } else if (user && 'message' in user) {
            toast.error(user.message);
          }
        })
        .catch((e) => {
          if (e.message === 'Unauthorized') {
            toast.error('Email ou mot de passe incorrect');
          }
        })
        .finally(() => {
          submitting.current = false;
        });
    }
  };

  useEffect(() => {
    if (!isServiceReady) {
      Service.init(() => {
        localStorage.removeItem('token');
        navigate('/login');
      });
    }
  }, []);

  return (
    <div className="flex items-center justify-center">
      <form
        className="bg-white p-16 rounded shadow-md w-96"
        onSubmit={handleSubmit}
      >
        <h1 className="text-2xl font-bold mb-8">Login</h1>
        <div className="mb-4">
          <label className="block mb-2" htmlFor="email">
            Email
          </label>
          <input
            className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="email"
            type="email"
            value={email.value}
            onChange={handleEmailChange}
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2" htmlFor="password">
            Mot de passe
          </label>
          <div className="relative flex items-center mb-3">
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type={showPassword ? 'text' : 'password'}
              value={password.value}
              onChange={handlePasswordChange}
            />
            <button
              type="button"
              className="absolute right-0 top-0 bottom-0 px-2 text-gray-400 hover:text-gray-900"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? (
                <EyeIcon className="h-5 w-5 text-gray-900" />
              ) : (
                <EyeSlashIcon className="h-5 w-5" />
              )}
            </button>
          </div>
        </div>
        <button
          type="submit"
          className="bg-blue-500 block p-2 rounded text-white w-full disabled:bg-gray-400"
          disabled={!email.value || !password.value}
        >
          Se connecter
        </button>
        <div className="mt-4 text-center">
          <a className="text-blue-500" href="/signup">
            Créer un compte
          </a>
        </div>
      </form>
    </div>
  );
};

export default Login;
