import { useEffect, useRef, useState } from 'react';
import { Station } from '../../../../../services/models/assets';
import Map from './Map';

interface FormNewProps {
  station: Partial<Station>;
  onStationChange: (station: Partial<Station>) => void;
  locationCoordinates?: { lat: number; lng: number };
}

const FormNew: React.FC<FormNewProps> = ({ station, onStationChange, locationCoordinates }) => {
  const [updatedStation, setUpdatedStation] = useState<Partial<Station>>(station ?? {});
  const timeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setUpdatedStation(station);
  }, [station]);

  const onValueChange = (key: keyof Station, value: string) => {
    setUpdatedStation((prev) => {
      const newStation =  {
        ...prev,
        [key]: value,
      };

      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        onStationChange(newStation);
      }, 500);

      return newStation;
    });
  };

  const updateStationCoordinates = (coordinates: { lat: number; lng: number }) => {
    setUpdatedStation((prev) => {
      const newStation = {
        ...prev,
        lat: coordinates.lat,
        lng: coordinates.lng,
      };

      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        onStationChange(newStation);
      }, 500);

      return newStation;
    });
  };

  return (
    <form>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="name">
          Token
        </label>
        <input
          className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="token"
          type="token"
          value={updatedStation.token ?? ''}
          onChange={(e) => onValueChange('token', e.target.value)}
        />
      </div>
      <label className="block mb-2" htmlFor="name">
        Position de la station
      </label>
      <div className="mb-4 h-96">
        <Map
          coordinates={locationCoordinates}
          markerCoordinates={updatedStation.lat != null && updatedStation.lng != null ? { lat: updatedStation.lat, lng: updatedStation.lng } : undefined}
          onCoordinatesChange={updateStationCoordinates}
        />
      </div>
    </form>
  );
};

export default FormNew;
