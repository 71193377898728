import { useMemo } from 'react';

interface TablePaginationProps {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const TablePagination = (props: TablePaginationProps) => {

  // Show max 3 pages, then ellipsis, then last page
  const pages = useMemo(() => Array.from({ length: props.totalPages }, (_, i) => i), [props.totalPages]);

  return (
    <div className="flex justify-center mt-4">
      <nav aria-label="Pagination">
        <ul className="inline-flex gap-2 justify-center items-center">
          {pages.map((page) => {
            if (page === props.page) {
              return (
                <li key={page}>
                  <span className="px-3 py-2 bg-gray-50 border border-gray-300 font-bold text-gray-900 rounded-md">{page + 1}</span>
                </li>
              );
            }

            if (page === 0 || page === props.totalPages - 1 || (page >= props.page - 2 && page <= props.page + 2)) {
              return (
                <li key={page}>
                  <button
                    onClick={() => props.onPageChange(page)}
                    className="px-3 py-2 hover:bg-gray-50 text-gray-900 rounded-md"
                  >
                    {page + 1}
                  </button>
                </li>
              );
            }

            if (page === props.page - 3 || page === props.page + 3) {
              return (
                <li key={page}>
                  <span className="m-0 px-3 py-2 text-gray-900 rounded-md">...</span>
                </li>
              );
            }

            return null;
          })}
        </ul>
      </nav>
    </div>
  );
};

export default TablePagination;
