import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import H1 from '../../../../components/H1';
import Tabs from '../../../../components/Tabs';
import AssetsService from '../../../../services/AssetsService';
import { Location } from '../../../../services/models/assets';
import FormDetails from './components/FormDetails';
import Schedule from './components/Schedule';

const DashboardLocationDetails = () => {
  const [location, setLocation] = useState<Location>();
  const [updatedLocation, setUpdatedLocation] = useState<Location>();

  const { id } = useParams<{ id: string }>();
  const { hash } = useLocation();

  const navigate = useNavigate();

  const isLocationChanged = useMemo(() => {
    if (!location || !updatedLocation) {
      return false;
    }
    return JSON.stringify(location) !== JSON.stringify(updatedLocation);
  }, [location, updatedLocation]);

  const tabs = useMemo(() => ([{
    name: 'Détails',
    hash: '#details',
    component: location ? (
      <FormDetails
        location={location}
        onLocationChange={(newLocation) => {
          setUpdatedLocation(newLocation);
          if (newLocation.bannerUrl !== updatedLocation?.bannerUrl) {
            updateLocation(newLocation);
          }
        }}
      />
    ) : null,
  }, {
    name: 'Horaires',
    hash: '#schedules',
    component: location ? <Schedule location={location} onLocationChange={setUpdatedLocation} /> : null,
  }]), [location]);

  const currentTab = useMemo(() => (
    tabs.find((tab) => tab.hash === hash)
  ), [tabs, hash]);

  useEffect(() => {
    refreshLocation();
  }, []);

  useEffect(() => {
    if (!tabs.map((tab) => tab.hash).includes(hash)) {
      navigate(`/location/${id}${tabs[0].hash}`);
    }
  }, [hash]);

  const refreshLocation = () => {
    if (!id) {
      navigate('/locations');
      toast.error('Location not found');
      return;
    }

    AssetsService.getLocation(parseInt(id, 10))
      .then((response) => {
        if (response.location) {
          setLocation(response.location);
          setUpdatedLocation(response.location);
        } else {
          navigate('/locations');
          toast.error('Location not found');
        }
      })
      .catch((error) => {
        console.warn(error);
        navigate('/locations');
        if (error.message) {
          toast.error(error.message);
        }
      });
  };

  const updateLocation = (newLocation = updatedLocation) => {
    // get difference between location and updatedLocation
    if (newLocation && location) {
      const changes = Object.keys(newLocation).reduce((acc, key) => {
        if (!['updatedAt', 'createdAt'].includes(key)
        && (key.includes('address') || JSON.stringify(location[key as keyof Location]) !== JSON.stringify(newLocation[key as keyof Location]))) {
          // workaround to avoid TS error
          acc[key as 'name'] = newLocation[key as keyof Location] as string;
        }
        return acc;
      }, {} as Partial<Location>);

      AssetsService.updateLocation(location.id, changes)
        .then((response) => {
          setLocation(response.location);
          setUpdatedLocation(response.location);
          toast.success('Emplacement mis à jour.');
        })
        .catch((error) => {
          console.warn(error);
          if (error.message) {
            toast.error(error.message);
          }
        });
    }
  };

  const resetUpdatedLocation = () => {
    setUpdatedLocation(location);
  };

  const toggleLocationActivation = () => {
    if (location) {
      (location.active
        ? AssetsService.deactivateLocation(location.id)
        : AssetsService.activateLocation(location.id))
        .then(() => {
          refreshLocation();
        })
        .catch(console.warn);
    }
  };

  return (
    <div className="pb-5 sm:pb-8 lg:pb-12">
      <H1>{location?.name ?? ''}</H1>
      <div className="mb-5 flex justify-between">
        <Tabs
          tabs={tabs.map((tab) => ({
              ...tab,
              path: tab.hash,
              current: hash === tab.hash,
              confirm: isLocationChanged,
          }))}
          onTabChange={resetUpdatedLocation}
        />
        <div className="flex items-end gap-3">
          <button
            type="button"
            className={`px-4 py-2 ${location?.active ? 'border border-red-400 text-red-400' : 'bg-green-400 text-white'} rounded-full font-semibold shadow disabled:shadow-none`}
            onClick={toggleLocationActivation}
          >
            {location?.active ? 'Désactiver' : 'Activer'}
          </button>
          <button
            type="button"
            className="px-4 py-2 bg-orange-400 rounded-full text-white font-semibold shadow disabled:shadow-none disabled:bg-gray-300"
            disabled={!isLocationChanged}
            onClick={() => updateLocation()}
          >
            Sauvegarder
          </button>
        </div>
      </div>
      {currentTab?.component}
    </div>
  );
};

export default DashboardLocationDetails;
