import { ChangeEvent, useRef, useState } from 'react';
import { Location } from '../../../../../services/models/assets';
import AssetsService from '../../../../../services/AssetsService';
import toast from 'react-hot-toast';

interface FormNewProps {
  location?: Partial<Location>;
  onLocationChange: (location: Partial<Location>) => void;
}

const FormNew: React.FC<FormNewProps> = ({ location, onLocationChange }) => {
  const [updatedLocation, setUpdatedLocation] = useState<Partial<Location>>(location ?? {});
  const [uploading, setUploading] = useState(false);

  const timeout = useRef<NodeJS.Timeout>();
  const bannerInput = useRef<HTMLInputElement>(null);

  const onValueChange = (key: keyof Location, value: string) => {
    setUpdatedLocation((prev) => {
      const newLocation =  {
        ...prev,
        [key]: value,
      };

      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        onLocationChange(newLocation);
      }, 500);

      return newLocation;
    });
  };

  const onImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files?.[0];

    if (image) {
      if (image.size > 5 * 1024 * 1024) {
        toast.error('L\'image est trop lourde (5Mb maximum)');
      } else if (!image.type.startsWith('image/')) {
        toast.error('Le fichier n\'est pas une image');
      }
      setUploading(true);
      AssetsService.uploadImage(image)
        .then((response) => {
          setUpdatedLocation((prev) => ({
            ...prev,
            bannerUrl: response.url,
          }));
          onLocationChange({
            ...updatedLocation,
            bannerUrl: response.url,
          });
        })
        .catch(() => {
          toast.error('Erreur lors de l\'envoi de l\'image');
        })
        .finally(() => {
          setUploading(false);
        });
    }
  };

  return (
    <form>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="image">
          Bannière
        </label>
        {uploading ? (
          <div className="flex w-full items-center justify-center h-60 bg-gray-200 rounded-lg">
            <p className="text-gray-500">Envoi de l'image...</p>
          </div>
        ) : (
          <button
            className="flex w-full items-center gap-3 justify-center hover:opacity-90"
            onClick={() => bannerInput.current?.click()}
          >
            <input
              type="file"
              id="banner"
              name="image"
              ref={bannerInput}
              className="hidden bg-gray-200"
              accept="image/*"
              onChange={onImageChange}
            />
            {updatedLocation.bannerUrl ? (
              <img src={updatedLocation.bannerUrl} alt="Banner" className="w-full h-60 bg-gray-200 rounded-lg object-cover" />
            ) : (
              <div className="w-full h-60 bg-gray-200 rounded-lg" />
            )}
            <div className="absolute">
              <p className="text-gray-900 text-xl font-bold">Choisir une image</p>
              <p className="text-gray-700 font-light">jpg, jpeg, png - 5Mb maximum</p>
            </div>
          </button>
        )}
      </div>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="name">
          Nom
        </label>
        <input
          className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="name"
          type="company-name"
          value={updatedLocation.name}
          onChange={(e) => onValueChange('name', e.target.value)}
        />
      </div>
      <div className="mb-4">
        <div className="flex justify-between">
          <label className="block mb-2" htmlFor="description">
            Description
          </label>
          <span className="text-gray-500">{`${updatedLocation.description?.length ?? 0}/1000`}</span>
        </div>
        <textarea
          className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline min-h-24"
          id="description"
          rows={4}
          maxLength={1000}
          value={updatedLocation.description ?? ''}
          onChange={(e) => onValueChange('description', e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="address-street">
          Nom de rue
        </label>
        <input
          className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="address-street"
          type="address-street"
          value={updatedLocation.addressStreet ?? ''}
          onChange={(e) => onValueChange('addressStreet', e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="address-street-2">
          Complément d'adresse
        </label>
        <input
          className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="address-street-2"
          type="address-street-2"
          value={updatedLocation.addressStreet_2 ?? ''}
          onChange={(e) => onValueChange('addressStreet_2', e.target.value)}
        />
      </div>
      <div className="mb-4 flex gap-5">
        <div className="flex-1">
          <label className="block mb-2" htmlFor="address-zip">
            Code postal
          </label>
          <input
            className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="address-zip"
            type="address-zip"
            value={updatedLocation.addressPostalCode ?? ''}
            onChange={(e) => onValueChange('addressPostalCode', e.target.value)}
          />
        </div>
        <div className="flex-1">
          <label className="block mb-2" htmlFor="address-city">
            Ville
          </label>
          <input
            className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="address-city"
            type="address-city"
            value={updatedLocation.addressCity ?? ''}
            onChange={(e) => onValueChange('addressCity', e.target.value)}
          />
        </div>
      </div>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="website">
          Lien vers le site web
        </label>
        <input
          className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="website"
          type="website"
          value={updatedLocation.websiteUrl ?? ''}
          onChange={(e) => onValueChange('websiteUrl', e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="terms-and-conditions">
          Lien vers les conditions générales (si nécessaire)
        </label>
        <input
          className="shadow appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="terms-and-conditions"
          type="terms-and-conditions"
          value={updatedLocation.termsUrl ?? ''}
          onChange={(e) => onValueChange('termsUrl', e.target.value)}
        />
      </div>
    </form>
  );
};

export default FormNew;
