import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import H1 from '../../../../components/H1';
import ModalRentalDetails from '../../../../components/Modals/ModalRentalDetails';
import Table from '../../../../components/Table/Table';
import Tabs from '../../../../components/Tabs';
import { Rental, RentalStatus } from '../../../../services/models/rental';
import RentalService from '../../../../services/RentalService';
import { PaginationMetadata } from '../../../../services/models/pagination';

const DashboardRentals = () => {
  const [rentals, setRentals] = useState<Rental[]>();
  const [rentalsMetadata, setRentalsMetadata] = useState<PaginationMetadata>();
  const [selectedRental, setSelectedRental] = useState<Rental>();
  const [page, setPage] = useState(0);

  const { hash } = useLocation();

  const navigate = useNavigate();

  const tabs = useMemo(() => ([{
    name: 'En cours',
    hash: '#current',
  }, {
    name: 'Historique',
    hash: '#history',
  }]), []);

  const tableHeader = useMemo(() => ([
    { name: 'Emplacement', key: 'location' },
    { name: 'Date', key: 'startAt' },
    { name: 'Durée', key: 'duration' },
    { name: 'Utilisateur', key: 'user' },
    { name: 'Caution', right: true, key: 'deposit' },
    { name: 'Prix', right: true, key: 'price' },
    { name: 'Status', right: true, key: 'status' },
  ]), []);

  const renderRentalStatus = (status: RentalStatus) => {
    switch (status) {
      case RentalStatus.NOT_STARTED:
        return (
          <div className="h-8 px-2 flex items-center bg-gray-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-gray-400 rounded-full" />
              <span className="text-green-900">Pas commencé</span>
            </div>
          </div>
        );

      case RentalStatus.RENTED:
        return (
          <div className="h-8 px-2 flex items-center bg-green-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-green-400 rounded-full" />
              <span className="text-gray-900">En cours</span>
            </div>
          </div>
        );

      case RentalStatus.RETURNED:
        return (
          <div className="h-8 px-2 flex items-center bg-red-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-red-400 rounded-full" />
              <span className="text-orange-900">En attente de vérification</span>
            </div>
          </div>
        );

      case RentalStatus.CLOSED:
        return (
          <div className="h-8 px-2 flex items-center bg-gray-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-gray-400 rounded-full" />
              <span className="text-green-900">Fermée</span>
            </div>
          </div>
        );

      default:
        return (
          <div className="h-8 px-2 flex items-center bg-red-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-red-400 rounded-full" />
              <span className="text-red-900">Inconnu</span>
            </div>
          </div>
        );
    }
  };

  const getDuration = (rental: Rental) => {
    const startDate = moment.utc(rental.startDateUTC);
    const endDate = moment.utc(rental.endDateUTC);

    if (endDate.isBefore(startDate)) {
      return <p className="text-red-500">Annulé</p>;
    }
    return `${moment.utc(rental.endDateUTC).diff(moment.utc(rental.startDateUTC), 'days')} jours`;
  };

  const tableData = useMemo(() => (
    rentals?.map((rental) => ({
      location: (
        (!rental.locationId || rental.location?.name === undefined) ? (
          <p className='text-gray-400'>Inconnu</p>
        ) : (
          <Link
            to={`/stations#${rental.locationId}`}
            className="text-blue-600 underline"
          >
            {rental.location.name}
          </Link>
        )
      ),
      id: rental.id,
      startAt: moment.utc(rental.startDateUTC).local().format('DD/MM/YYYY HH:mm'),
      duration: getDuration(rental),
      user: `${rental.user.firstName} ${rental.user.lastName}`,
      deposit: `${(rental.deposit / 100).toFixed(2)} €`,
      price: `${(rental.price / 100).toFixed(2)} €`,
      status: renderRentalStatus(rental.rentalStatus),
      key: rental.id,
    })) ?? []
  ), [rentals]);

  useEffect(() => {
    if (!tabs.map((tab) => tab.hash).includes(hash)) {
      navigate(`/rentals${tabs[0].hash}`);
    } else {
      setRentals(undefined);
      setRentalsMetadata(undefined);
      if (page === 0) {
        refreshRentals();
      }
    }
    setPage(0);
  }, [hash]);

  useEffect(() => {
    refreshRentals();
  }, [page]);

  const onRentalPress = (data: (typeof tableData)[number]) => {
    setSelectedRental(rentals?.find((rental) => rental.id === data.id));
  };

  const refreshRentals = () => {
    setSelectedRental(undefined);
    (tabs[0].hash.includes(hash)
      ? RentalService.getRentalsCurrent(page)
      : RentalService.getRentalsHistory(page))
      .then((response) => {
        setRentals(response.rentals);
        setRentalsMetadata(response.metadata);
      })
      .catch(console.warn);
  };

  return (
    <div className="pb-5 sm:pb-8 lg:pb-12">
      <H1>Réservations</H1>
      <Tabs
        tabs={tabs.map((tab) => ({
            ...tab,
            path: tab.hash,
            current: hash === tab.hash,
        }))}
      />
      <div className="mt-5">
        <Table
          header={tableHeader}
          data={tableData}
          onRowPress={onRentalPress}
          page={page}
          totalPages={rentalsMetadata?.totalPages}
          onPageChange={setPage}
        />
        <ModalRentalDetails
          rental={selectedRental}
          visible={!!selectedRental}
          onClose={() => setSelectedRental(undefined)}
          onEndRental={refreshRentals}
          onUpdate={refreshRentals}
        />
      </div>
    </div>
  );
};

export default DashboardRentals;
