import { CheckIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import H1 from '../../../../components/H1';
import AssetsService from '../../../../services/AssetsService';
import { Location, Station } from '../../../../services/models/assets';
import FormNew from './components/FormNew';

const DashboardStationNew = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [locationCoordinates, setLocationCoordinates] = useState<{ lat: number, lng: number}>();
  const [newStation, setNewStation] = useState<Partial<Station>>({});

  const navigate = useNavigate();

  useEffect(() => {
    AssetsService.getLocations()
      .then((response) => {
        setLocations(response.locations);
        if (response.locations.length === 1) {
          setNewStation((prev) => ({ ...prev, locationId: response.locations[0].id }));
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (newStation.locationId != null) {
      const location = locations.find((loc) => loc.id === newStation.locationId);

      if (location) {
        setLocationCoordinates({
          lat: location.lat,
          lng: location.lng,
        });
      }
    }
  }, [newStation.locationId]);

  const isNewStationValid = useCallback(() => {
    return (
      !!newStation?.token
      && !!newStation?.lat
      && !!newStation?.lng
      && !!newStation?.locationId
    );
  }, [newStation]);

  const createStation = useCallback(() => {
    AssetsService.linkStation(newStation)
      .then(() => {
        toast.success('Station créé');
        navigate('/locations');
    })
      .catch((e) => {
        toast.error('Le token est invalide ou la station est déjà liée à un autre emplacement');
        console.error(e);
      });
  }, [newStation]);

  return (
    <div>
      <H1 className="mt-5 sm:mt-8 lg:mt-12">Choisissez un emplacement pour la station</H1>
      <ul role="list" className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6 xl:gap-x-8">
        {locations.map((location) => (
          <li key={location.id} className="relative">
            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-2xl bg-gray-100 shadow-xl relative">
              {location.bannerUrl ? (
                <img src={location.bannerUrl} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
              ) : (
                <div className="pointer-events-none object-cover group-hover:opacity-85 bg-gray-800" />
              )}
              <button
                type="button"
                className="absolute inset-0 focus:outline-none flex flex-col justify-start items-start text-left"
                onClick={() => setNewStation((prev) => ({ ...prev, locationId: location.id }))}
              >
                <div className="absolute top-0 right-0 left-0 bg-white h-full opacity-30" />
                <p className={`mt-2 ml-2 pointer-events-none block truncate text-lg z-10 font-medium ${location.bannerUrl ? 'text-gray-900' : 'text-white'}`}>{location.name}</p>
                <p className={`block mt-2 ml-2 text-sm z-10 ${location.bannerUrl ? 'text-gray-900' : 'text-white'}`}>
                  {`${location.addressStreet}${location.addressStreet_2 ? ` ${location.addressStreet_2}` : ''}, ${location.addressPostalCode} ${location.addressCity}, ${location.addressCountry}`}
                </p>
                <input
                  type="checkbox"
                  className="appearance-none bg-white border border-gray-300 shadow rounded-full h-8 w-8 focus:ring-0 hover:cursor-pointer absolute bottom-2 right-2 checked:bg-green-400 checked:border-green-600 checked:shadow-none checked:text-white checked:ring-0"
                  checked={newStation?.locationId === location.id}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  onChange={() => {}}
                />
                {newStation?.locationId === location.id && (
                  <CheckIcon className="absolute bottom-3.5 right-3.5 h-5 w-5 text-white" />
                )}
              </button>
            </div>
          </li>
        ))}
      </ul>
      <H1 className="mt-5 sm:mt-8 lg:mt-12">Entrez le token lié à votre station</H1>
      <FormNew station={newStation} onStationChange={setNewStation} locationCoordinates={locationCoordinates} />
      <div className="flex justify-end pb-5 mt-5 sm:pb-8 sm:mt-5 lg:pb-12 lg:mt-5">
        <button
          type="button"
          className="px-4 py-2 bg-orange-400 rounded-full text-white font-semibold shadow disabled:shadow-none disabled:bg-gray-300"
          disabled={!isNewStationValid()}
          onClick={createStation}
        >
          Créer
        </button>
      </div>
    </div>
  );
};

export default DashboardStationNew;
