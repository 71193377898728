import { PlusIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import H1 from '../../../components/H1';
import AssetsService from '../../../services/AssetsService';
import { Location } from '../../../services/models/assets';

const DashboardLocation = () => {
  const [locations, setLocations] = useState<Location[]>([]);

  useEffect(() => {
    AssetsService.getLocations()
      .then((response) => {
        setLocations(response.locations);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  return (
    <div className="pb-5 sm:pb-8 lg:pb-12">
      <H1>Mes emplacements</H1>
      <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 xl:gap-x-8">
        {locations.map((location) => (
          <li key={location.id} className="relative">
            <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-2xl bg-gray-100 shadow-xl relative">
              {location.bannerUrl ? (
                <img src={location.bannerUrl} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
              ) : (
                <div className="pointer-events-none object-cover group-hover:opacity-85 bg-gray-800" />
              )}
              <Link to={`/location/${location.id}`} type="button" className="absolute inset-0 focus:outline-none">
                <span className="sr-only">View details for {location.name}</span>
                <div className="absolute top-0 right-0 left-0 bg-white h-10 opacity-30" />
                <p className={`pointer-events-none block truncate text-lg font-medium z-10 ${location.bannerUrl ? 'text-gray-900' : 'text-white'} absolute top-2 left-2`}>{location.name}</p>
                <div className="flex gap-1 items-center absolute bottom-0 right-0 px-3 py-1">
                  <div className="absolute top-0 bottom-0 left-0 right-0 bg-gray-900 opacity-50 rounded-tl-md" />
                  {location.active ? (
                    <>
                      <span className="z-10 text-green-400">Active</span>
                      <div className="z-10 h-3 w-3 bg-green-400 rounded-full" />
                    </>
                  ) : (
                    <>
                      <span className="z-10 text-red-400">Inactive</span>
                      <div className="z-10 h-3 w-3 bg-red-400 rounded-full" />
                    </>
                  )}
                </div>
              </Link>
            </div>
            <p className="pointer-events-none block mt-2 ml-3 text-sm text-gray-900">
              {`${location.addressStreet}${location.addressStreet_2 ? ` ${location.addressStreet_2}` : ''}, ${location.addressPostalCode} ${location.addressCity}, ${location.addressCountry}`}
            </p>
            <p className="pointer-events-none block ml-3 text-sm font-light text-gray-500">
              {`${location.stations.length} station${location.stations.length > 1 ? 's' : ''}`}
            </p>
          </li>
        ))}
        <li className="relative">
          <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-2xl bg-gray-100 shadow-lg relative">
            <div className="pointer-events-none object-cover group-hover:opacity-85 bg-gray-200" />
            <Link to="/location/new" className="absolute flex flex-col inset-0 focus:outline-none items-center justify-center">
              <span className="sr-only">Create new location</span>
              <p className="pointer-events-none block truncate text-lg font-medium text-gray-900 absolute top-5 left-0 right-0 text-center">Ajouter un nouvel emplacement</p>
              <PlusIcon width={60} className="inline-block" />
            </Link>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DashboardLocation;
