export interface User {
  email: string;
  firstName: string;
  lastName: string;
  street: string;
  street2: string;
  postalCode: string;
  city: string;
  country: string;
  lang: string;
  token?: string;
}

export interface RegisterBody extends User {
  [keyof: string]: string | boolean | number | undefined;
  termsAccepted: boolean;
  password: string;
  confirmPassword: string;
}

// password regex: 8 characters min, 1 uppercase, 1 lowercase, 1 number
export const REGEX_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
export const REGEX_EMAIL = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;