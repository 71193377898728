import { Bars3Icon, PlusIcon, PowerIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from '../types/common';

interface SideMenuProps {
  menus: Menu[];
}

const SideMenu: React.FC<SideMenuProps> = (props) => {
  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();

  return (
    <div className="z-10 pointer-events-none pt-20 w-full xl:h-full xl:w-80 xl:pt-0">
      <div className={`w-80 h-full shadow-lg fixed bg-orange-400 ${visible ? 'z-100 flex flex-col' : 'hidden xl:flex flex-col'} right-0 xl:right-auto`}>
        <div className="flex-1">
          <ul className="pointer-events-auto p-5 pr-0 h-full">
            <p className="text-6xl font-semibold text-white pb-5 italic bg-orange-400">Follow</p>
            {props.menus.map((menu, index) => (
              <li
                key={index}
                className="-ml-5 pl-5"
              >
                <Link
                  to={menu.path}
                  className={`pl-5 py-5 flex gap-3 items-start border-b border-orange-400 font-medium text-lg rounded-s-xl ${(pathname.includes(menu.path) || menu.includes?.reduce((bool, path) => bool || pathname.includes(path), false)) ? 'bg-white text-orange-500' : 'text-white hover:bg-white hover:text-orange-400'}`}
                  onClick={() => setVisible(false)}
                >
                  {menu.icon}{menu.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <Link
          to="/station/new"
          className="pointer-events-auto ml-5 pl-5 py-5 flex gap-3 items-start border-b border-orange-400 font-medium text-lg rounded-s-xl text-white hover:bg-white hover:text-orange-400"
          onClick={() => setVisible(false)}
        >
          <PlusIcon width={24} />Ajouter une station
        </Link>
        <Link
          to="/logout"
          className="pointer-events-auto ml-5 mb-5 pl-5 py-5 flex gap-3 items-start border-b border-orange-400 font-medium text-lg rounded-s-xl text-white hover:bg-white hover:text-orange-400"
          onClick={() => setVisible(false)}
        >
          <PowerIcon width={24} />Se déconnecter
        </Link>
      </div>
      <div className="pointer-events-auto w-full h-20 shadow-lg fixed bg-orange-400 right-0 top-0 flex items-center px-5 justify-between xl:hidden">
        <p className="text-6xl font-semibold text-white italic bg-orange-400">Follow</p>
        <button
          type="button"
          className="p-2 flex justify-center items-center text-white"
          onClick={() => setVisible((prev) => !prev)}
        >
          {visible ? (
            <XMarkIcon height={40} />
          ) : (
            <Bars3Icon height={40} />
          )}
        </button>
      </div>
    </div>
  );
};

export default SideMenu;
