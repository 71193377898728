import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import H1 from '../../../components/H1';
import H2 from '../../../components/H2';
import AssetsService from '../../../services/AssetsService';
import {
  Box,
  Equipment,
  EquipmentStateOfUse,
  EquipmentType,
  Location,
  LockStatus,
  Station,
} from '../../../services/models/assets';
import Table from '../../../components/Table/Table';
import { toast } from 'react-hot-toast';
import { RentalStatus } from '../../../services/models/rental';
import ModalChooseEquipment from '../../../components/Modals/ModalChooseEquipment';
import ModalClosedMessage from '../../../components/Modals/ModalClosedMessgae';

const DashboardStations = () => {
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocationId, setSelectedLocationId] = useState<number>();
  const [selectedStationId, setSelectedStationId] = useState<number>();
  const [selectedBox, setSelectedBox] = useState<Box>();
  const [modalClosedMessageVisible, setModalClosedMessageVisible] = useState(false);

  const { hash } = useLocation();

  const navigate = useNavigate();

  const selectedStation = useMemo(() => {
    if (!selectedLocationId || !selectedStationId) {
      return;
    }
    return locations
      .find((location) => location.id === selectedLocationId)
      ?.stations.find((station) => station.id === selectedStationId);
  }, [locations, selectedLocationId, selectedStationId]);

  const selectStation = (locationId: number, stationId: number) => {
    setSelectedLocationId(locationId);
    setSelectedStationId(stationId);
  };

  const tableHeaderLocations = useMemo(() => [
    { name: 'ID', key: 'id' },
    { name: 'Coordonnées', key: 'coordinates' },
    { name: 'Tension batterie', key: 'battery' },
    { name: 'Boxes', key: 'boxes' },
    { name: 'Status', key: 'status' },
    { name: 'Select', hidden: true, right: true, key: 'select' },
  ], []);

  const toggleStationActivation = (station: Station) => {
    if (station.active) {
      setModalClosedMessageVisible(true);
      setSelectedStationId(station.id);
    } else {
      AssetsService.activateStation(station.id)
        .then(() => {
          AssetsService.getLocations()
            .then((response) => setLocations(response.locations))
            .catch(console.warn);
        })
        .catch(console.warn);
    }
  };

  const tableDataLocations = useMemo(() => (
    locations.map((location) => ({
      name: location.name,
      data: location.stations.map((station) => ({
        id: location.id,
        station,
        coordinates: `${station.lat}, ${station.lng}`,
        battery: `${station.voltage} V`,
        boxes: station.boxes.length,
        status: (
          <div className="flex gap-5 items-center">
            <div className={`h-3 w-3 ${station.active ? 'bg-green-400' : 'bg-red-400'} rounded-full`} />
            <button
              type="button"
              className="py-2 px-4 flex items-center justify-center border bg-white rounded-md shadow-sm font-semibold"
              onClick={() => toggleStationActivation(station)}
            >
              <span className="text-gray-500">{station.active ? 'Désactiver' : 'Activer'}</span>
            </button>
          </div>
        ),
        select: selectedStationId === station.id ? (
          <div className="py-2 px-4 flex items-center justity-center bg-gray-100 border border-gray-200 rounded-md">
            <span className="text-gray-500">Sélectionné</span>
          </div>
        ) : (
          <button
            type="button"
            className="py-2 px-4 flex items-center justify-center border bg-orange-400 rounded-md shadow-sm font-semibold"
            onClick={() => selectStation(location.id, station.id)}
          >
            <span className="text-white">Voir</span>
          </button>
        ),
        key: station.id,
      })),
    }))
  ), [locations, selectedStation]);

  const tableHeaderStation = useMemo(() => {
    if (!selectedStation) {
      return [];
    }
    return [
      { name: 'Type', key: 'type' },
      { name: 'Modèle', key: 'model' },
      { name: 'État', key: 'stateOfUse' },
      { name: 'Prix', key: 'price' },
      { name: 'Caution', key: 'deposit' },
      { name: 'Réservation', right: true, key: 'rentalStatus' },
    ];
  }, [selectedStation]);

  const renderRentalStatus = (status: RentalStatus) => {
    switch (status) {
      case RentalStatus.NOT_STARTED:
        return (
          <div className="h-8 px-2 flex items-center bg-green-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-green-400 rounded-full" />
              <span className="text-green-900">Disponible</span>
            </div>
          </div>
        );

      case RentalStatus.RENTED:
        return (
          <div className="h-8 px-2 flex items-center bg-gray-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-gray-400 rounded-full" />
              <span className="text-gray-900">Loué</span>
            </div>
          </div>
        );

      case RentalStatus.RETURNED:
        return (
          <div className="h-8 px-2 flex items-center bg-orange-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-orange-400 rounded-full" />
              <span className="text-orange-900">Retourné</span>
            </div>
          </div>
        );

      default:
        return (
          <div className="h-8 px-2 flex items-center bg-red-200 rounded-md">
            <div className="flex gap-1 items-center">
              <div className="h-3 w-3 bg-red-400 rounded-full" />
              <span className="text-red-900">Inconnu</span>
            </div>
          </div>
        );
    }
  };

  const renderStateOfUse = (state: EquipmentStateOfUse) => {
    switch (state) {
      case EquipmentStateOfUse.NEW:
        return 'Neuf';

      default:
        return 'Usagé';
    }
  };

  const renderType = (type: EquipmentType) => {
    switch (type) {
      case EquipmentType.SURF:
        return 'Surf';

      case EquipmentType.PADDLE:
        return 'Paddle';

      case EquipmentType.KAYAK:
        return 'Kayak';

      case EquipmentType.BIKE:
        return 'Vélo';

      default:
        return 'Inconnu';
    }
  };

  const renderLockStatus = (status: LockStatus) => {
    switch (status) {
      case LockStatus.OPENED:
        return (
          <div className="h-8 px-2 flex items-center justity-center bg-red-200 rounded-md">
            <span className="text-red-900">Ouvert</span>
          </div>
        );

      case LockStatus.CLOSED:
        return (
          <div className="h-8 px-2 flex items-center justity-center bg-green-200 rounded-md">
            <span className="text-green-900">Fermé</span>
          </div>
        );

      default:
        return (
          <div className="h-8 px-2 flex items-center justity-center bg-gray-200 rounded-md">
            <span className="text-gray-900">Inconnu</span>
          </div>
        );
    }
  };

  const toggleBoxActivation = (box: Box) => {
    (box.active
      ? AssetsService.deactivateBox(box.id)
      : AssetsService.activateBox(box.id))
      .then(() => {
        AssetsService.getLocations()
          .then((response) => setLocations(response.locations))
          .catch(console.warn);
      })
      .catch(console.warn);
  };

  const openBox = (box: Box) => {
    AssetsService.openBox(box.id)
      .then(() => {
        toast.success('Action envoyé');
      })
      .catch(console.warn);
  };

  const renderActions = (box: Box) => {
    return (
      <div className="flex gap-2">
        <button
          type="button"
          className="font-semibold"
          onClick={() => openBox(box)}
        >
          <span className="text-blue-400 hover:underline">Ouvrir</span>
        </button>
        <p className="-mt-0.5 text-gray-400">|</p>
        <button
          type="button"
          className="font-semibold hover:underline disabled:hover:no-underline text-blue-400 disabled:text-gray-500"
          disabled={box.equipments.find((equipment) => equipment.rentalStatus === RentalStatus.RENTED) != null}
          onClick={() => setSelectedBox(box)}
        >
          <span>
            {box.equipments.length > 0 ? 'Modifier' : 'Ajouter'}
          </span>
        </button>
        <p className="-mt-0.5 text-gray-400">|</p>
        <button
          type="button"
          className={`font-semibold ${box.active ? 'text-red-400' : 'text-green-400'} hover:underline disabled:hover:no-underline disabled:text-gray-500`}
          disabled={box.equipments.find((equipment) => equipment.rentalStatus === RentalStatus.RENTED) != null}
          onClick={() => toggleBoxActivation(box)}
        >
          <span>{box.active ? 'Désactiver' : 'Activer'}</span>
        </button>
      </div>
    );
  };

  const tableDataStation = useMemo(() => {
    if (!selectedStation) {
      return [];
    }
    return selectedStation.boxes.map((box) => ({
      name: `Box #${box.boxNumber}`,
      data: box.equipments.map((equipment) => ({
        type: renderType(equipment.type),
        model: (
          <div className="max-w-80">
            <p className="text-gray-900 text-wrap font-medium line-clamp-2">
              {equipment.model}
            </p>
            <p className="text-gray-700 text-wrap text-xs line-clamp-2">
              {equipment.description}
            </p>
          </div>
        ),
        price: (
// `${equipment.priceDay / 100} €/jour\n${equipment.priceHalfDay / 100} €/demi-journée\n${equipment.price2Hours / 100} €/2 heures\n${equipment.price1Hour / 100} €/heure`,
          <div className="flex flex-col">
            <p className="text-xs text-gray-900">{`${(equipment.priceDay / 100).toFixed(2)} € / jour`}</p>
            <p className="text-xs text-gray-900">{`${(equipment.priceHalfDay / 100).toFixed(2)} € / demi-journée`}</p>
            <p className="text-xs text-gray-900">{`${(equipment.price2Hours / 100).toFixed(2)} € / 2 heures`}</p>
            <p className="text-xs text-gray-900">{`${(equipment.price1Hour / 100).toFixed(2)} € / heure`}</p>
          </div>
        ),
        deposit: `${equipment.deposit / 100} €`,
        stateOfUse: renderStateOfUse(equipment.stateOfUse),
        rentalStatus: renderRentalStatus(equipment.rentalStatus),
        key: equipment.id,
      })),
      rentalStatus: renderActions(box),
      model: box.active
        ? (
          <div className="flex gap-2 items-center">
            {renderLockStatus(box.lockStatus)}
            <span className="text-green-400">Status: Box Actif</span>
          </div>
        ) : (
          <div className="flex gap-2 items-center">
            {renderLockStatus(box.lockStatus)}
            <span className="text-red-400">Status: Box Inactif</span>
          </div>
        ),
    }));
  }, [selectedStation]);

  useEffect(() => {
    const saveStations = (locations: Location[]) => {
      setLocations(locations);

      if (!selectedStationId) {
        setSelectedLocationId((prev) => {
          if (prev) {
            return prev;
          }
          return locations[0]?.id;
        });
        setSelectedStationId((prev) => {
          if (prev) {
            return prev;
          }
          return locations[0]?.stations[0]?.id;
        });
      }
    };
    AssetsService.getLocations()
      .then((response) => saveStations(response.locations))
      .catch(console.warn);
    const interval = setInterval(() => {
      AssetsService.getLocations()
        .then((response) => saveStations(response.locations))
        .catch((e) => {
          console.error(e);
        });
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (hash !== `#${selectedLocationId}`) {
      const newId = parseInt(hash.replace('#', ''));

      const location = locations.find((location) => location.id === newId);
      if (location) {
        setSelectedLocationId(location.id);
        setSelectedStationId(location.stations[0].id);
      } else if (selectedLocationId) {
        navigate(`/stations#${selectedLocationId}`);
      } else {
        navigate('/stations');
      }
    }
  }, [hash]);

  useEffect(() => {
    if (selectedLocationId && selectedStationId) {
      navigate(`/stations#${selectedLocationId}`);
    }
  }, [selectedLocationId, selectedStationId]);

  const clearSelectedBox = () => {
    setSelectedBox(undefined);
  };

  const updateEquipmentInSelectedBox = (equipment: Equipment) => {
    if (!selectedBox) {
      return;
    }
    AssetsService.updateBoxEquipment(selectedBox.id, equipment.id)
      .then(() => {
        AssetsService.getLocations()
          .then((response) => setLocations(response.locations))
          .catch(console.warn);
      })
      .catch(console.warn);
  };

  const deactivateStation = (stationId: number, message?: string) => {
    AssetsService.deactivateStation(stationId, message)
      .then(() => {
        setModalClosedMessageVisible(false);
        AssetsService.getLocations()
          .then((response) => setLocations(response.locations))
          .catch(console.warn);
      })
      .catch(console.warn);
  };

  return (
    <div className="pb-5 sm:pb-8 lg:pb-12">
      <H1>Stations</H1>
      <Table
        header={tableHeaderLocations}
        data={tableDataLocations}
      />
      {selectedStation && (
        <div className="mt-5 sm:mt-8 lg:mt-12">
          <H2>{`Station #${selectedStation.id}`}</H2>
          <Table
            header={tableHeaderStation}
            data={tableDataStation}
          />
        </div>
      )}
      <ModalChooseEquipment
        visible={selectedBox != null}
        selectedEquipmentId={selectedBox?.equipments[0]?.id}
        onClose={clearSelectedBox}
        onEquipmentSelect={updateEquipmentInSelectedBox}
      />
      <ModalClosedMessage
        visible={modalClosedMessageVisible}
        stationId={selectedStationId}
        onCancel={() => setModalClosedMessageVisible(false)}
        onConfirm={deactivateStation}
      />
    </div>
  );
};

export default DashboardStations;
