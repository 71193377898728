import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModalChangesNotSaved from './Modals/ModalChangesNotSaved';

interface Tab {
  name: string;
  path: string;
  current: boolean;
  confirm?: boolean;
}

interface TabsProps {
  tabs: Tab[];
  onTabChange?: (tab: Tab) => void;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Tabs = ({ tabs, onTabChange }: TabsProps) => {
  const [nextTab, setNextTab] = useState<Tab>();

  const navigate = useNavigate();

  const onTabClick = (tab: Tab) => {
    if (tab.confirm) {
      setNextTab(tab);
    } else {
      navigate(tab.path);
      onTabChange?.(tab);
    }
  };

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base sm:text-sm"
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                className={classNames(
                  tab.current
                    ? 'border-gray-500 text-gray-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap border-b-2 py-2 px-3 text-lg font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
                onClick={() => onTabClick(tab)}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <ModalChangesNotSaved
        visible={nextTab != null}
        onCancel={() => setNextTab(undefined)}
        onConfirm={() => {
          navigate(nextTab!.path);
          setNextTab(undefined);
          onTabChange?.(nextTab!);
        }}
      />
    </div>
  );
};

export default Tabs;
