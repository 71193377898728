import SideMenu from './components/SideMenu';
import { Outlet, useNavigate } from 'react-router-dom';
import { Menu } from './types/common';
import { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import Service, { isServiceReady } from '../../services/Service';

interface DashboardProps {
  menus: Menu[];
}

const Dashboard: React.FC<DashboardProps> = (props) => {
  const [serviceReady, setServiceReady] = useState(isServiceReady);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isServiceReady) {
      Service.init(() => {
        localStorage.removeItem('token');
        navigate('/login');
      });
    }

    UserService.validateToken()
      .then((isTokenValid) => {
        if (!isTokenValid) {
          localStorage.removeItem('token');
          navigate('/login');
        } else {
          setServiceReady(true);
        }
      })
      .catch(console.warn);
  }, []);

  return (
    <div className="flex h-screen w-full relative flex-col xl:flex-row">
      <SideMenu menus={props.menus} />
      <div className="flex-1 p-5 pt-12 max-w-5xl mx-auto w-full">
        {(serviceReady && (
          <Outlet />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
