import { useCallback, useState } from 'react';
import H1 from '../../../../components/H1';
import { Location } from '../../../../services/models/assets';
import Schedule from '../DashboardLocationDetails/components/Schedule';
import FormNew from './components/FormNew';
import AssetsService from '../../../../services/AssetsService';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const DashboardLocationNew = () => {
  const [newLocation, setNewLocation] = useState<Partial<Location>>();

  const navigate = useNavigate();

  const isNewLocationValid = useCallback(() => {
    return (
      !!newLocation?.name
      && !!newLocation?.addressStreet
      && !!newLocation?.addressPostalCode
      && !!newLocation?.addressCity
      && (newLocation?.schedules?.length ?? 0 > 0)
    );
  }, [newLocation]);

  const createLocation = useCallback(() => {
    if (newLocation) {
      AssetsService.createLocation(newLocation)
        .then(() => {
          toast.success('Emplacement créé');
          navigate('/locations');
        })
        .catch(console.warn);
    }
  }, [newLocation]);

  return (
    <div>
      <H1>Nouvel emplacement</H1>
      <FormNew
         location={newLocation}
         onLocationChange={setNewLocation}
      />
      <H1 className="mt-5 sm:mt-8 lg:mt-12">Horaires</H1>
      <Schedule
        location={newLocation}
        onLocationChange={setNewLocation}
      />
      <div className="flex justify-end pb-5 mt-5 sm:pb-8 sm:mt-5 lg:pb-12 lg:mt-5">
        <button
          type="button"
          className="px-4 py-2 bg-orange-400 rounded-full text-white font-semibold shadow disabled:shadow-none disabled:bg-gray-300"
          disabled={!isNewLocationValid()}
          onClick={createLocation}
        >
          Créer
        </button>
      </div>
    </div>
  );
};

export default DashboardLocationNew;
