interface H1Props {
  className?: string;
  children: string;
}

const H2 = ({ children, className = '' }: H1Props) => (
  <h2 className={`text-2xl lg:text-3xl font-bold text-gray-900 py-6 ${className}`}>
    {children}
  </h2>
);

export default H2;
