interface H1Props {
  className?: string;
  children: string;
}

const H1 = ({ children, className = '' }: H1Props) => (
  <h1 className={`text-3xl sm:text-4xl lg:text-5xl text-gray-900 pb-5 sm:pb-8 lg:pb-12 font-light ${className}`}>
    {children}
  </h1>
);

export default H1;
