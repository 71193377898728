type Options = Omit<RequestInit, 'body'> & { body?: string | Record<string, any> };

let handleUnauthorized: () => void;

export let isServiceReady = false;

class Service {
  static init(handleUnauthorizedCallback: () => void) {
    handleUnauthorized = handleUnauthorizedCallback;
    isServiceReady = true;
  }

  static async put<T>(url: string, body: Record<string, any>, options: Options = {}): Promise<T> {
    return await this.fetch(url, {
      ...options,
      method: 'PUT',
      body,
    });
  }

  static async post<T>(url: string, body: Record<string, any>, options: Options = {}): Promise<T> {
    return await this.fetch(url, {
      ...options,
      method: 'POST',
      body,
    });
  }

  static async delete<T>(url: string, options: Options = {}): Promise<T> {
    return await this.fetch(url, {
      ...options,
      method: 'DELETE',
    });
  }

  static async get<T>(url: string, options: Options = {}): Promise<T> {
    return await this.fetch(url, {
      ...options,
      method: 'GET',
    });
  }

  static async patch<T>(url: string, body: Record<string, any>, options: Options = {}): Promise<T> {
    return await this.fetch(url, {
      ...options,
      method: 'PATCH',
      body,
    });
  }

  static async fetch<T>(url: string, options: Options = {}, ignoreUnauthorized?: boolean): Promise<T> {
    if (!isServiceReady) {
      throw new Error('Service not ready. Call Service.init() before using it.');
    }
    const updatedOptions = {
      ...options,
    };

    const token = localStorage.getItem('token');

    if (token) {
      updatedOptions.headers = {
        ...options?.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    if (options.body) {
      updatedOptions.headers = {
        ...updatedOptions.headers,
        ...(options.body instanceof FormData ? {} : { 'Content-Type': 'application/json' }),
        'Accept': 'application/json',
      };
      if (typeof options.body !== 'string' && !(options.body instanceof FormData)) {
        updatedOptions.body = JSON.stringify(options.body);
      }
    }

    const response = await fetch(url, updatedOptions as RequestInit);

    if (!response.ok) {
      if (response.status === 401 && !ignoreUnauthorized) {
        handleUnauthorized();
      }
      throw await response.json();
    }
    return await response.json() as T;
  }
}

export default Service;
