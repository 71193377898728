import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import AssetsService from '../../services/AssetsService';
import { Equipment } from '../../services/models/assets';
import { Link } from 'react-router-dom';

interface ModalChooseEquipmentProps {
  selectedEquipmentId?: number;
  visible: boolean;
  onClose: () => void;
  onEquipmentSelect: (equipment: Equipment) => void;
}

const ModalChooseEquipment: React.FC<ModalChooseEquipmentProps> = ({
  selectedEquipmentId,
  visible,
  onClose,
  onEquipmentSelect,
}) => {
  const [selectedEquipment, setSelectedEqupment] = useState<Equipment>();
  const [equipments, setEquipments] = useState<Equipment[]>([]);

  useEffect(() => {
    if (visible) {
      AssetsService.getEquipments()
        .then((response) => {
          setEquipments(response.equipments.filter((e) => e.boxId == null || e.id === selectedEquipment?.id));
          if (selectedEquipmentId != null) {
            setSelectedEqupment(response.equipments.filter((e) => e.boxId == null || e.id === selectedEquipment?.id).find((equipment) => equipment.id === selectedEquipmentId));
          }
        })
        .catch((error) => {
          console.error('Error fetching equipments:', error);
        });
    } else {
      setSelectedEqupment(undefined);
    }
  }, [visible]);

  const handleEquipmentSelect = (equipment: Equipment) => {
    onEquipmentSelect(equipment);
    onClose();
  };

  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={onClose}>
        <div className="flex items-center justify-center min-h-screen px-4 text-center">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg">
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                Choisissez un équipement
              </Dialog.Title>
              <div className="mt-4">
                <ul className="grid grid-cols-1 gap-2">
                  {equipments.map((equipment) => (
                    <li
                      key={equipment.id}
                      className="flex-1"
                    >
                      <button
                        className={`w-full px-4 py-2 rounded-md flex gap-2 items-center justify-between ${selectedEquipment?.id === equipment.id ? 'bg-blue-100' : 'bg-gray-100 disabled:opacity-50'}`}
                        onClick={() => handleEquipmentSelect(equipment)}
                        disabled={equipment.boxId != null}
                      >
                        <div className="flex gap-2 items-center">
                          <img src={equipment.imageUrl} alt={equipment.model} className="w-10 h-10 rounded-lg" />
                          <p className="text-gray-900">{equipment.model}</p>
                        </div>
                        <p className="text-gray-900">{`${(equipment.priceDay / 100).toFixed(2)} €/jour`}</p>
                      </button>
                    </li>
                  ))}
                  {equipments.length === 0 && (
                    <li>
                      <p className="text-gray-900">Pas d'équipements disponibles.</p>
                      <Link to="/equipments" className="text-blue-500">Créer un équipement</Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalChooseEquipment;
