import { RentalStatus } from './rental';

export enum EquipmentStateOfUse {
  NEW = 1,
  USED = 2,
}

export enum EquipmentType {
  SURF = 1,
  PADDLE = 2,
  KAYAK = 3,
  BIKE = 4,
}

export interface Equipment {
  id: number;
  model: string;
  description: string;
  priceDay: number;
  priceHalfDay: number;
  price2Hours: number;
  price1Hour: number;
  deposit: number;
  imageUrl: string;
  stateOfUse: EquipmentStateOfUse;
  rentalStatus: RentalStatus;
  type: EquipmentType;
  createdAt: string;
  updatedAt: string;
  boxId: number;
}

export enum LockStatus {
  OPENED = 'opened',
  CLOSED = 'closed',
}

export interface Box {
  id: number;
  boxNumber: number;
  active: boolean;
  lockStatus: LockStatus;
  createdAt: string;
  updatedAt: string;
  stationId: number;
  equipments: Equipment[];
}

export interface Station {
  id: number;
  lat: number;
  lng: number;
  token: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  locationId: number;
  voltage?: number;
  boxes: Box[];
}

export enum Day {
  MONDAY = 0,
  TUESDAY = 1,
  WEDNESDAY = 2,
  THURSDAY = 3,
  FRIDAY = 4,
  SATURDAY = 5,
  SUNDAY = 6,
}

export interface LocationSchedule {
  locationId: number;
  day: Day;
  startTime: string;
  endTime: string;
}

export interface Location {
  id: number;
  name: string;
  description?: string;
  addressStreet: string;
  addressStreet_2?: string;
  addressPostalCode: string;
  addressCity: string;
  addressCountry: string;
  lat: number;
  lng: number;
  bannerUrl?: string;
  mapUrl?: string;
  termsUrl?: string;
  websiteUrl?: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  userId: number;
  stations: Station[];
  schedules: LocationSchedule[];
}
